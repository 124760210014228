'use client';

import { Input, Select } from '@components/inputs';
import { useSiteInfo } from '@context/siteInfoContext';
import { useForm, ValidationError } from '@formspree/react';
import { Button } from '@ngg/components';
import type { Form, KeyValue, Maybe } from '@server/gql/graphql';
import { useCallback, useMemo, useState } from 'react';
import type { Dictionary } from 'types/common';
import xss from 'xss';

import { cn } from '@/lib/utils';
import { createDictionary } from '@/utils/common';

const FORMSPREE_BOOK_MEETING_FORM_ID = 'xnqkover';

export default function BookMeeting({
    className,
    reference,
    dictionary,
}: {
    className?: string;
    reference?: Maybe<string>;
    dictionary: Dictionary;
}) {
    const { globalDictionary } = useSiteInfo();
    const [state, handleSubmit] = useForm(
        reference || FORMSPREE_BOOK_MEETING_FORM_ID,
    );
    const [email, setEmail] = useState<string | undefined>(undefined);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }

    const isInputError = useCallback(
        (inputName: string) =>
            state?.errors.some((error) => error.field === inputName),
        [state.errors],
    );

    const consultations = useMemo(
        () => [
            {
                id: 1,
                value: dictionary.stockholm,
                label: dictionary.stockholm,
            },
            {
                id: 2,
                value: dictionary.gothenburg,
                label: dictionary.gothenburg,
            },
            {
                id: 3,
                value: dictionary.malmo,
                label: dictionary.malmo,
            },
            {
                id: 4,
                value: dictionary.digitalMeeting,
                label: dictionary.digitalMeeting,
            },
        ],
        [dictionary],
    );

    const bookMeetingFormSuccessText = useMemo(
        () =>
            dictionary.bookMeetingFormSuccessText.replace(
                '%EMAIL%',
                `<strong>${email}</strong>`,
            ),
        [email, dictionary.bookMeetingFormSuccessText],
    );

    if (state.succeeded) {
        return (
            <>
                <h2 className="text-lg font-bold">
                    {dictionary.bookMeetingFormSuccessHeading}
                </h2>
                <p
                    className="mt-4 text-sm"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                        __html: xss(bookMeetingFormSuccessText),
                    }}
                />
            </>
        );
    }

    return (
        <form onSubmit={handleSubmit} className={cn('max-w-4xl', className)}>
            {dictionary.bookMeetingFormHeading && (
                <h3 className="w-full text-lg font-medium">
                    {dictionary.bookMeetingFormHeading}
                </h3>
            )}
            <div className="mt-6 space-y-3">
                <Input
                    id="name"
                    name="name"
                    type="text"
                    required
                    fullWidth
                    label={
                        dictionary.bookMeetingFormNameLabel ||
                        globalDictionary.name
                    }
                    classNames={{
                        input: isInputError('name')
                            ? 'border border-error'
                            : '',
                    }}
                />
                <ValidationError
                    className="mt-2 text-sm"
                    field="name"
                    errors={state.errors}
                />
                <Input
                    id="email"
                    name="email"
                    type="email"
                    required
                    fullWidth
                    label={
                        dictionary.bookMeetingFormEmailLabel ||
                        globalDictionary.email
                    }
                    classNames={{
                        input: isInputError('email')
                            ? 'border border-error'
                            : '',
                    }}
                    onChange={(event) => handleChange(event)}
                />
                <ValidationError
                    className="mt-2 text-sm"
                    field="email"
                    errors={state.errors}
                />
                <Input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    fullWidth
                    label={
                        dictionary.bookMeetingFormPhoneNumberLabel ||
                        globalDictionary.phoneNumber
                    }
                    classNames={{
                        input: isInputError('phoneNumber')
                            ? 'border border-error'
                            : '',
                    }}
                />
                <ValidationError
                    className="mt-2 text-sm"
                    field="phoneNumber"
                    errors={state.errors}
                />
                <Select
                    name="consultation"
                    placeholder={
                        dictionary.bookMeetingFormConsultationPlaceholder ||
                        globalDictionary.consultation
                    }
                    options={consultations}>
                    <ValidationError
                        className="mt-2 text-sm"
                        field="room"
                        errors={state.errors}
                    />
                </Select>
            </div>
            <Button
                type="submit"
                isLoading={state.submitting}
                fullWidth
                className="btn-primary mt-6">
                {dictionary.bookMeetingFormSubmitButton ||
                    globalDictionary.send}
            </Button>
            {/* Honeypot */}
            <input type="text" name="_gotcha" className="hidden" />
        </form>
    );
}

export function BookMeetingPopup({ form }: { form?: Maybe<Form> }) {
    const formDictionary = createDictionary(
        form?.dictionaryCollection?.items as KeyValue[],
    );

    return (
        <div className="mx-auto max-w-[420px] bg-white px-4 py-8 md:px-6">
            <BookMeeting dictionary={formDictionary} className="mt-4" />
        </div>
    );
}
